
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol class=\"icon\" viewBox=\"0 0 1024 1024\"  id=\"icon-link\"><path d=\"M883.46 504.827a46.043 46.043 0 0 1-60.709-.938 41.61 41.61 0 0 1-3.496-58.236l77.591-77.933a163.539 163.539 0 0 0-16.967-229.107c-67.36-67.274-166.864-73.328-223.054-16.712l-216.83 218.364a163.539 163.539 0 0 0 17.054 229.107 41.61 41.61 0 0 1 0 60.368 45.617 45.617 0 0 1-63.437 0 246.33 246.33 0 0 1-16.968-347.285L593.474 65.37c93.28-93.45 251.616-85.777 352.485 17.053a246.246 246.246 0 0 1 15.604 343.619l-78.103 78.785zm-742.318 13.642a46.043 46.043 0 0 1 60.708.938c16.798 15.518 18.418 40.927 3.496 58.236l-77.591 77.932a163.539 163.539 0 0 0 16.968 229.108c67.36 67.274 166.864 73.328 223.053 16.712l216.83-218.364a163.539 163.539 0 0 0-17.054-229.108 41.61 41.61 0 0 1 0-60.367 45.532 45.532 0 0 1 63.438 0 246.33 246.33 0 0 1 16.968 347.285l-216.83 217.17c-93.28 93.365-251.617 85.691-352.486-17.053A246.246 246.246 0 0 1 63.04 597.339l78.103-78.785z\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}